import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/components/error404.module.css'; // Importação do módulo CSS

const Error404 = () => {
  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorCode}>
        <h1>404</h1>
      </div>
      <div className={styles.verticalLine}></div>
      <div className={styles.errorMessage}>
        <h2>Esta página não pode ser encontrada.</h2>
        <p>Você pode continuar relaxando por aqui, ou voltar para o site.</p>
        <Link to="/" className={styles.backHomeButton}>Voltar para a Home</Link>
      </div>
    </div>
  );
};

export default Error404;
