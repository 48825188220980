import React from 'react';
import BudgetForm from '../layouts/BudgetForm';
import BudgetDetails from '../layouts/BudgetDetails';
import styles from '../styles/pages/budget.module.css';

const Budget = () => {
  return (
    <div className={styles.container}>
      <div className={styles.contactHeader}>
        <div>
          <h1>Vamos <span>conversar?</span></h1>
          <p>Preencha o formulário e entraremos em contato o mais rápido possível.</p>
        </div>
        <BudgetForm />
      </div>
      <div className={styles.contactContent}>
        <BudgetDetails />
      </div>
    </div>
  );
};

export default Budget;
