import React, { useState } from 'react';
import MemberCarousel from './MemberCarousel';
import styles from '../styles/layouts/memberCarousels.module.css';

const MemberCarousels = ({ members }) => {
  const [activeTab, setActiveTab] = useState('diretores');

  const renderCarousel = () => {
    switch (activeTab) {
      case 'diretores':
        return <MemberCarousel members={members.diretores} />;
      case 'setorProjetos':
        return <MemberCarousel members={members.setorProjetos} />;
      case 'setorComercial':
        return <MemberCarousel members={members.setorComercial} />;
      case 'setorGestao':
        return <MemberCarousel members={members.setorGestao} />;
      case 'posJuniores':  // Novo setor
        return <MemberCarousel members={members.posJuniores} />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Nossos Membros</h2>
      <div className={styles.title_underline}></div>
      <p className={styles.subtitle}>Confira nossos integrantes e seus setores</p>
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === 'diretores' ? styles.active : ''}`}
          onClick={() => setActiveTab('diretores')}
        >
          Diretores
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'setorProjetos' ? styles.active : ''}`}
          onClick={() => setActiveTab('setorProjetos')}
        >
          Setor de Projetos
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'setorComercial' ? styles.active : ''}`}
          onClick={() => setActiveTab('setorComercial')}
        >
          Setor Comercial
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'setorGestao' ? styles.active : ''}`}
          onClick={() => setActiveTab('setorGestao')}
        >
          Setor de Gestão
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'posJuniores' ? styles.active : ''}`}  // Novo botão
          onClick={() => setActiveTab('posJuniores')}
        >
          Pós-Juniores
        </button>
      </div>
      {renderCarousel()}
    </div>
  );
};

export default MemberCarousels;
