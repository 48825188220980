import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import styles from '../styles/pages/home.module.css';
import forte_megafone from '../assets/forte_megafone.png';
import fundoReuniao from '../assets/fundo_reuniao.jpeg';
import site from '../assets/site.png';
import mao from '../assets/mao.png';
import dados from '../assets/dados.png';
import mobile from '../assets/mobile.png';
import ecommerce from '../assets/ecommerce.png';
import consultoria from '../assets/consultoria.png';
import codigo from '../assets/codigo.png';
import blog from '../assets/blog.png';
import Card from '../layouts/Card';
import LinkButton from '../layouts/LinkButton';
import AboutUsHome from '../layouts/AboutUsHome';
import SuccessCases from '../layouts/SuccessCases';
import Testimonials from '../layouts/Testimonials';
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

const Home = () => {
  const settings = {
    dots: true, // Mostrar os pontos de navegação
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false, // Desabilitar setas
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  const [couterOn, setCounterOn] = useState(false);

  return (
    <>
      <section className={styles.home_container}>
        <div className={styles.text_content}>
          <h1><span>Bem vindo à Linked</span></h1>
          <h3>Somos uma empresa júnior dedicada a transformar ideias em soluções inovadoras.</h3>
          <p>Explore nossos serviços e veja como podemos ajudar a sua empresa a crescer.</p>
          <LinkButton to="/services" text="Nossos Serviços" />
          <LinkButton to="/budget" text="Entre em Contato" className="btnSecond" />
        </div>
        <img src={forte_megafone} alt="extra_forte" />
      </section>

      <section className={styles.new_container}>
        <div className={styles.text_pag2}>
          <h1>ALGUNS DE NOSSOS SERVIÇOS</h1>
          <h3>Venha programar sua história com a gente!</h3>
        </div>

        <Slider {...settings} className={styles.slider}>
          <div className={styles.cardWrapper}>
            <Card img={site} title='Site Institucional' text="Ideal para apresentar sua empresa de forma profissional na internet." />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={codigo} title='Sistemas' text="Deixamos os processos da sua empresa automatizados de acordo com as rotinas desenvolvidas." />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={dados} title='Dados' text="Identifica padrões, melhora processos, otimiza recursos e identifica oportunidades de crescimento." />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={mobile} title='Mobile' text="Criamos apps ágeis e intuitivos para conectar sua empresa aos usuários de forma prática e eficiente." />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={consultoria} title='Consultoria' text="É focada em disponibilizar nossas expertises para ajudar seu negocio." />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={ecommerce} title='E-commerce' text="E-commerces tornam possível vender e comprar um produto ou serviços pela internet." />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={mao} title='Portfólio' text="Um portfólio é um catálogo online, no qual você demonstra as especificações de seus produtos e serviços. " />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={blog} title='Blog' text="São utilizados tanto por criadores de conteúdo, empresas ou artistas." />
          </div>
        </Slider>
      </section>

      <section className={styles.stats_section} style={{ backgroundImage: `url(${fundoReuniao})` }}>
        <div className={styles.overlay}></div>
        <div className={styles.stats_container}>
          <div className={styles.stat_card}>
            <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}> 
              <h2> +{couterOn && <CountUp start={0} end={45} duration={2} delay={0} />}</h2>
            </ScrollTrigger>
            <p>Projetos executados</p>
          </div>
          <div className={styles.stat_card}>
            <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}> 
              <h2> +{couterOn && <CountUp start={0} end={15} duration={2} delay={0} />}</h2>
            </ScrollTrigger>
            <p>Anos de atuação</p>
          </div>
          <div className={styles.stat_card}>
            <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}> 
              <h2> {couterOn && <CountUp start={0} end={9} duration={2} delay={0} />}</h2>
            </ScrollTrigger>
            <p>Média de NPS</p>
          </div>
          <div className={styles.stat_card}>
            <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}> 
              <h2> {couterOn && <CountUp start={0} end={3} duration={2} delay={0} />}x</h2>
            </ScrollTrigger>
            <p>EJ Impacto</p>
          </div>
        </div>
      </section>

      <SuccessCases />
      <AboutUsHome />
      <Testimonials />
    </>
  );
};

export default Home;
