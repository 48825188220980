import React from 'react';
import LinkButton from '../layouts/LinkButton';

/* Assets */
import service1 from '../assets/site.png';
import service2 from '../assets/codigo.png';
import service3 from '../assets/dados.png';
import service4 from '../assets/consultoria.png';
import service5 from '../assets/ecommerce.png';
import service6 from '../assets/mao.png';
import service7 from '../assets/blog.png';
import service8 from '../assets/mobile.png';
import img1     from '../assets/img-website.png';
import img2     from '../assets/Desafio_Consistente.jpg'
import img3     from '../assets/img-dados.jpg'
import img4     from '../assets/img-consultoria.jpg'
import img5     from '../assets/img-ecommerce.jpg'
import img6     from '../assets/img-portfolio.png'
import img7     from '../assets/img-blog.png'
import img8     from '../assets/mobile-concept.jpg'

/* Styles */
import '../styles/index.module.css';
import styles from '../styles/pages/services.module.css';

const data = [
  { icon: service1, img: img1, name: 'Site Institucional', desc: 'Desenvolvemos websites modernos e responsivos que não apenas cativam visualmente, mas também proporcionam uma experiência de usuário intuitiva. Nossos sites são personalizados para refletir a identidade única de cada cliente, garantindo uma presença online marcante e eficaz.' },
  { icon: service2, img: img2, name: 'Sistema Web',        desc: 'Desenvolvemos sistemas web sob medida para atender às necessidades específicas do seu negócio. Otimize seus processos internos e melhore a eficiência operacional com nossas soluções personalizadas.' },
  { icon: service3, img: img3, name: 'Dados',              desc: 'Oferecemos consultoria em ciência de dados e automação de processos, capacitando sua empresa a tomar decisões estratégicas com base em dados concretos.' },
  { icon: service4, img: img4, name: 'Consultoria',        desc: 'Fornecemos consultoria especializada em desenvolvimento web, com foco na criação e otimização de websites e sistemas web personalizados.' },
  { icon: service5, img: img5, name: 'E-commerce',         desc: 'Construímos lojas virtuais que combinam design atraente e funcionalidades poderosas. Nossas soluções em e-commerce otimizam a experiência de compra, aumentam a conversão e impulsionam suas vendas online.' },
  { icon: service6, img: img6, name: 'Portfólio',          desc: 'Desenvolvemos portfólios online atraentes e funcionais, que destacam seus melhores projetos e habilidades. Com um design envolvente e uma navegação intuitiva, ajudamos você a impressionar clientes e oportunidades.' },
  { icon: service7, img: img7, name: 'Blog',               desc: 'Criamos blogs personalizados, focados em design moderno e fácil navegação. Com nossas soluções, você pode compartilhar conteúdo de forma profissional, atrair seu público e expandir sua presença online.' },
  { icon: service8, img: img8, name: 'Mobile',             desc: 'Desenvolvemos aplicativos mobile intuitivos e eficientes, garantindo uma experiência fluida e otimizada para usuários em qualquer dispositivo. Nossas soluções são personalizadas para atender às necessidades do seu negócio e do seu público.' }
];

const Services = () => {
  const ServiceComponent = ({ icon, img, name, desc }) => (<>
    <div className={styles.service}>
      <img src={img} alt={name} className={styles.service_image} />
      <div className={styles.service_text}>
        <h3 className={styles.service_title}>
          <img src={icon} alt="Icon" className={styles.icon} /> {name}
        </h3>
        <p> {desc} </p>
      </div>
    </div>
    <hr className={styles.divider} />
  </>);

  return (
    <section className={styles.success_services_section}>
      <h1> Nossos Serviços </h1>
      <div className={styles.title_underline} />
      <h2> Venha Descobrir Nossos Serviços </h2>
      <p> Explore nossos serviços e veja como podemos ajudar a sua empresa a crescer. </p>

      {data.map(item => <ServiceComponent {...item} />)}

      <LinkButton to="/cases" text="Explorar cases"/>
    </section>
  );
};

export default Services;
