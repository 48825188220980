import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import styles from '../styles/layouts/aboutUsCompany.module.css';
import imagem1 from '../assets/img-equipe1.jpg';
import imagem2 from '../assets/img-equipe2.png';
import imagem3 from '../assets/img-equipe3.png';
import imagem4 from '../assets/img-equipe4.png';
import imagem5 from '../assets/img-equipe5.png';
import imagem6 from '../assets/img-equipe6.png';
import imagem7 from '../assets/img-equipe7.png';
import imagem8 from '../assets/img-equipe8.png';
import imagem9 from '../assets/img-equipe9.png';
import imagem10 from '../assets/img-equipe10.png';
import imagem11 from '../assets/img-equipe11.png';

// Setas personalizadas
const NextArrow = ({ onClick }) => (
  <div className={styles.custom_next_arrow} onClick={onClick}>
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className={styles.custom_prev_arrow} onClick={onClick}>
  </div>
);

const AboutUsCompany = () => {
  const images = [
    imagem1, imagem2, imagem3, imagem4, imagem5, imagem6, imagem7, imagem8, imagem9, imagem10, imagem11
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className={styles.success_services_section}>
      {/* <h1>Conheça Nossos Membros</h1>
      <div className={styles.title_underline}></div> */}
      <div className={styles.image_container}>
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index} className={styles.slide}>
              <img src={img} alt={`equipe ${index + 1}`} className={styles.about_image} />
            </div>
          ))}
        </Slider>
      </div>

      <div className={styles.about_content}>
        <h2>Nossa História</h2>
        <p>
          A Linked é a Empresa Júnior do curso de Ciência da Computação da Universidade Federal de São João del-Rei.
          Criada e gerida por estudantes, nossa missão é oferecer soluções tecnológicas inovadoras, garantindo qualidade
          e satisfação ao cliente. Promovemos o desenvolvimento pessoal e profissional dos nossos membros através da
          execução de projetos de alta qualidade, contribuindo para o progresso econômico e empresarial.
        </p>
        <p>
          Valorizamos a inovação e o acolhimento, direcionando nossos lucros para criar oportunidades tanto para nossos
          membros quanto para a comunidade da computação. Compartilhamos nosso conhecimento para democratizar o acesso ao
          mercado de computação, trabalhando para construir um ambiente colaborativo e acessível a todos.
        </p>
      </div>
    </section>
  );
};

export default AboutUsCompany;
