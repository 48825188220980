import React from 'react';
import styles from '../styles/layouts/successCases.module.css';
import IconTextButton from '../layouts/IconTextButton';
import LinkButton from './LinkButton';
import case5 from '../assets/img-site-linked.jpg'; 
import case2 from '../assets/arché.png'; 

const SuccessCasesHome = () => {
  return (
    <section className={styles.success_cases_section}>
      <h2>CASES DE SUCESSO</h2>
      <div className={styles.title_underline}></div> {/* Adicionando o risco */}
      <p>Descubra como a Linked tem transformado ideias em soluções de sucesso. Confira alguns dos nossos projetos realizados:</p>

      {/* Site Linked Case */}
      <div className={styles.case}>
        <img src={case5} alt="Site Linked" className={styles.case_image} />
        <div className={styles.case_text}>
          <h3>Site Linked</h3>
          <p>Plataforma para networking e exibição de portfólios, com funcionalidades dinâmicas para conectar profissionais.</p>
          <IconTextButton to="/project-details/site-linked" text="Explore Mais" className="btnSecond" />
        </div>
      </div>
      
      <hr className={styles.divider} />

      {/* Arche Case */}
      <div className={styles.case}>
        <div className={styles.case_text}>
          <h3>Arche Empresa Junior</h3>
          <p>Site para uma empresa júnior de arquitetura, focado em gestão de projetos e presença digital.</p>
          <IconTextButton to="/project-details/arche-empresa-junior" text="Explore Mais" className="btnSecond" />
        </div>
        <img src={case2} alt="Arche Empresa Junior" className={styles.case_image} />
      </div>

      <div className={styles.more_cases}>
        <LinkButton to="/cases" text="Ver todos os cases"/>
      </div>
    </section>
  );
};

export default SuccessCasesHome;
