import React from 'react';

/* layouts */
import AboutUsCompany from '../layouts/AboutUsCompany';
import Mvv from '../layouts/Mvv';
import MemberCarousels from '../layouts/MemberCarousels'; 
import Partners from '../layouts/Partners';

const membersData = {
  diretores: [
    { name: 'Messias Feres', role: 'Presidente', image: require('../assets/Membros/Atual/Messias_Feres.png'), linkedin: 'https://www.linkedin.com/in/messiasfcm/', github: 'https://github.com/MessiasFCM' },
    { name: 'Lucas Rivetti', role: 'Diretor de Projetos', image: require('../assets/Membros/Atual/Rivetti.jpg'), linkedin: 'https://www.linkedin.com/in/lucas-rivetti', github: 'https://github.com/LucasRivetti' },
    { name: 'Oscar Alves', role: 'Diretor Administrativo', image: require('../assets/Membros/Atual/Oscar.jpg'), linkedin: 'https://www.linkedin.com/in/oscar-alves-11ab00172/' },
    { name: 'Gustavo Euller', role: 'Diretor Comercial', image: require('../assets/Membros/Atual/Gustavo_Euller.jpg') },
    { name: 'Brian Alves', role: 'CM', image: require('../assets/Membros/Atual/Braian.jpg'), linkedin: 'https://www.linkedin.com/in/brian-alves/' }
  ],
  setorProjetos: [
    { name: 'João Antônio', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Joao_Antonio_ex_Linked.png'), linkedin: 'https://www.linkedin.com/in/scjoaoantonio/', github: 'https://github.com/scjoaoantonio' },
    { name: 'Bruno Costa', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Bruno.jpg'), linkedin: 'https://www.linkedin.com/in/bruno-oliveira-82883129b/' },
    { name: 'Elias Mendes', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Elias.jpg'), linkedin: 'https://www.linkedin.com/in/elias-m-1390b2141/' },
    { name: 'Bernardo Detomi', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/bernardo-maia-detomi-7621a51b9/', github: 'https://github.com/BernardoDetomi' },
    { name: 'Luiz Felipe', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Luiz_Felipe.jpg') },
    { name: 'Vitor Niess', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Vitor_Niess.jpg'), linkedin: 'https://www.linkedin.com/in/vitorniess/' },
    { name: 'Nathan Mateus', role: 'Assessor de Projetos', linkedin: 'https://linkedin.com/in/nathan-lima-aa56ba312/' },
    { name: 'Bernardo Rodrigues', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/bernardo-rodrigues-568349340/' },
    { name: 'Marlon Silveira', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/marlonsbas/', github: 'https://github.com/marlonsbasilio' },
    { name: 'Renan Rodrigues', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/renan-sousa-9457a9162/', github: 'https://github.com/Renan-Sousa' },
    { name: 'Rebeca Martins', role: 'Assessora de Projetos', linkedin: 'https://www.linkedin.com/in/rebeca-martins-guimar%C3%A3es-37b235336/' },
    { name: 'Adélson de Oliveira', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/ad%C3%A9lson-j%C3%BAnior-85a970327/' },
    { name: 'Gabriel Gomes', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/gabriel-gomes-47780b207/' },
    { name: 'Pedro Henrique', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/pedrohldev', github: 'https://github.com/phladev' },
    { name: 'Alan Vale', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/alan-vale-ba432324a/', github: 'https://github.com/alaanvv' }
  ],
  setorComercial: [
    { name: 'Lívia Maia', role: 'Assessora Comercial' },
    { name: 'João Pedro', role: 'Assessor Comercial', linkedin: 'https://www.linkedin.com/in/jo%C3%A3opedro135/' },
    { name: 'Ester maria', role: 'Assessora Comercial', },
    { name: 'Antônio Gustavo', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Antonio.jpg') },
    { name: 'Leonardo Patias', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Leonardo.jpg') },
    { name: 'Matheus de Oliveira', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Matheus.jpg'), linkedin: 'https://www.linkedin.com/in/mateuso-brito/' },
    { name: 'Vitor José', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Vitor_José.jpg') }
  ],
  setorGestao: [
    { name: 'Lara Caroline', role: 'Assessora Administrativa', image: require('../assets/Membros/Atual/Lara.jpg') },
    { name: 'Rafael Shivaom', role: 'Assessor Administrativa', linkedin: 'https://www.linkedin.com/in/rafael-shivaom-899259336/' }
  ],
  posJuniores: [
    { name: 'Ian Nunes', role: 'Ex-Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/ian-nunes-379686266/' },
    { name: 'Dilvonei Alves', role: 'Ex-Assessor de Projetos', image: require('../assets/Membros/Pós_Juniores/Dilvonei.jpg'), linkedin: 'https://www.linkedin.com/in/dilvonei-alves-lacerda-05328a228/', github: 'https://github.com/DilvoneiL' },
    { name: 'Davi Greco', role: 'Ex-Assessor de Projetos', image: require('../assets/Membros/Pós_Juniores/Davi_Greco.jpg'), linkedin: 'https://www.linkedin.com/in/davi-greco-4285b9275/', github: 'https://github.com/DaviGreco' },
    { name: 'Davi Gomes', role: 'Ex-Assessor de Projetos', image: require('../assets/Membros/Pós_Juniores/Davi_Gomes.jpg'), linkedin: 'https://www.linkedin.com/in/davi-gomes-coelho-49b24a184', github: 'https://github.com/davigpc' },
    { name: 'Marco Aurélio', role: 'Ex-Assessor Administrativo', image: require('../assets/Membros/Pós_Juniores/Marcu.jpg'), linkedin: 'https://www.linkedin.com/in/marcoquaglietta/' },
    { name: 'Ana Júlia', role: 'Ex-Assessora Comercial', image: require('../assets/Membros/Pós_Juniores/Julia.jpg'), linkedin: 'https://www.linkedin.com/in/ana-julia-silva-ledo-b8386828a', github: 'https://github.com/julialedo' },
    { name: 'Guilherme Anicio', role: 'Ex-Assessor Comercial', image: require('../assets/Membros/Pós_Juniores/Guilherme_Sasa.jpg'), linkedin: 'https://www.linkedin.com/in/guilherme-m-anicio/' },
    { name: 'Thaís Apolinário', role: 'Ex-Assessora Comercial', image: require('../assets/Membros/Pós_Juniores/Thais_Apolinario.jpg'), linkedin: 'https://www.linkedin.com/in/tha%C3%ADs-apolin%C3%A1rio-196994259/' },
    { name: 'Lucas Leite', role: 'Ex-Diretor Comercial', image: require('../assets/Membros/Pós_Juniores/Lucas_Costa.png'), linkedin: 'http://www.linkedin.com/in/lucas-leite-888953299', github: 'https://github.com/lucaselc' },
    { name: 'Lucas Bernardes', role: 'Ex-Diretor Administrativo', image: require('../assets/Membros/Pós_Juniores/Lucas_Bernardes.jpg'), linkedin: 'https://www.linkedin.com/in/lucas-eduardo-bernardes-de-paula-5615b7256/', github: 'https://github.com/LucasBernardess' },
    { name: 'André Chagas', role: 'Ex-Diretor de Projetos', image: require('../assets/Membros/Pós_Juniores/Andre_Chagas.jpg'), github: 'https://github.com/andrechagaslima' },
    { name: 'Gustavo Romão', role: 'Ex-Presidente' },
    { name: 'Rafael Marques', role: 'Ex-Presidente', image: require('../assets/Membros/Pós_Juniores/Rafael_Marques.jpeg'), linkedin: 'https://www.linkedin.com/in/rafael-campos-656559217/', github: 'https://github.com/Markesxd' },
    { name: 'Douglas José', role: 'Ex-Assessor de Projetos', image: require('../assets/Membros/Pós_Juniores/Douglas_José_Barboza.jpg'), linkedin: 'https://www.linkedin.com/in/douglasjosebarboza/', github: 'https://github.com/douglasjosebarboza' },
    { name: 'Paulo Eduardo', role: 'Ex-Diretor de Projetos', image: require('../assets/Membros/Pós_Juniores/Paulo.jpg') },
    { name: 'Joice Cristina', role: 'Ex-Representante de Projetos', image: require('../assets/Membros/Pós_Juniores/Joice_Cristina.jpeg'), linkedin: 'https://www.linkedin.com/in/joicecssantos/', github: 'https://github.com/Joice-crypto' }
  ]
};

const Company = () => {
  return (
    <>
      <AboutUsCompany />
      <Mvv />
      <Partners />
      <MemberCarousels members={membersData} />
    </>
  );
};

export default Company;
