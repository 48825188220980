import React from 'react';
import styles from '../styles/components/footerBudget.module.css';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.logo}>Linked</div>
      </div>
      <p className={styles.copyRight}>
        Copyright &copy; <span>2024 Linked</span>
      </p>
    </footer>
  );
}

export default Footer;
