import IconTextButton from '../layouts/IconTextButton';
import React, { useState } from 'react';

// Assets
// TODO Use a pattern to name assets
import case1 from '../assets/buffet.png';
import case2 from '../assets/arché.png';
import case3 from '../assets/tremkivoa.png';
import case4 from '../assets/milhas.png';
import case5 from '../assets/img-site-linked.jpg';
import case6 from '../assets/ecommerce_site.png';

// Styles
import styles from '../styles/pages/cases.module.css';
import btn from '../styles/layouts/linkButton.module.css';

const casesData = [
  { img: case5, name: 'Site Linked',                  desc: 'Plataforma para networking e exibição de portfólios, com funcionalidades dinâmicas para conectar profissionais.', path: '/project-details/site-linked' },
  { img: case2, name: 'Arche Empresa Junior',         desc: 'Site para uma empresa júnior de arquitetura em São João del-Rei, focado em gestão de projetos e presença digital.', path: '/project-details/arche-empresa-junior' },
  { img: case1, name: 'Buffet Sonho Real Tiradentes', desc: 'Site expositivo e portfólio para um buffet em Tiradentes, focado na modernização e aumento de reservas online.', path: '/project-details/buffet-sonho-real-tiradentes' },
  { img: case4, name: 'Milhas Gerais',                desc: 'Site expositivo para o projeto Milhas Gerais da UFSJ, focado em aumentar a visibilidade e engajamento.', path: '/project-details/milhas-gerais' },
  { img: case3, name: 'Trem Ki Voa Aerodesign',       desc: 'Site expositivo para o projeto de aerodesign da UFSJ, focado em aumentar a visibilidade e engajamento.', path: '/project-details/trem-ki-voa-aerodesign' },
  { img: case6, name: 'Ecommerce',                    desc: 'Site para o portfólio da empresa. Um ecommerce com sistemas funcionais de cadastro e compra.', path: '/project-details/ecommerce' }
];

const Cases = () => {
  const [showAllCases, setShowAllCases] = useState(false);

  const handleLoadMoreClick = () => {
    setShowAllCases(!showAllCases);
  };

  const CaseComponent = ({ img, name, desc, path }) => (<>
    <div className={styles.case}>
      <img src={img} alt={name} className={styles.case_image} />
      <div className={styles.case_text}>
        <h3>{name}</h3>
        <p>{desc}</p>
        <IconTextButton to={path} text="Explore Mais" className={btn.btnSecond} />
      </div>
    </div>
    <hr className={styles.divider} />
  </>);

  return (
    <section className={styles.success_cases_section}>
      <h1 className={styles.title}> Cases de Sucesso </h1>
      <div className={styles.title_underline} />
      <h2 className={styles.subtitle}> Conheça nossos projetos </h2>
      <p className={styles.description}> Descubra como nossos serviços podem beneficiar sua empresa. </p>

      {casesData.slice(0, showAllCases ? casesData.length : 4).map(item => <CaseComponent {...item} />)}

      <button className={btn.btn} onClick={handleLoadMoreClick}>
        {showAllCases ? 'Carregar Menos' : 'Carregar Mais'}
      </button>
    </section>
  )
};

export default Cases;
