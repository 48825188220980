import React from 'react';
import styles from '../styles/pages/budget.module.css';
import emailjs from '@emailjs/browser';
import ReactInputMask from 'react-input-mask';
import toast from 'react-hot-toast';

const BudgetForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const templateParams = {
      from_name: formData.get('nome'),
      email: formData.get('email'),
      telefone: formData.get('telefone'),
      servico: formData.get('servico'),
      mensagem: formData.get('mensagem'),
    };

    emailjs.send(
      "service_linked",
      "template_linked",
      templateParams,
      "SHUC3ueu5lf3I2TYs",
    )
      .then(response => {
        if (response.status === 200) {
          toast.success('Pedido enviado com sucesso!');
        } else {
          toast.error('Ocorreu um erro ao enviar o pedido.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Ocorreu um erro ao enviar o pedido.');
      });

    e.target.reset(); // Reseta o formulário após o envio
  };

  return (
    <form className={styles.budgetForm} onSubmit={(handleSubmit)}>
      <label>
        Nome Completo
        <input type="text" name="nome" placeholder="Digite seu nome" required />
      </label>
      <label>
        Email
        <input type="email" name="email" placeholder="Digite seu email" required />
      </label>
      <label>
        Telefone
        <ReactInputMask mask="(99)99999-9999" type="tel" name="telefone" placeholder="(32) 99999-9999" required />
      </label>
      <label>
        Serviço
        <input type="text" name="servico" placeholder="Ex: Preciso de um site" required />
      </label>
      <label>
        Mensagem
        <textarea name="mensagem" placeholder="Faça uma breve descrição de sua mensagem" required></textarea>
      </label>
      <button type="submit" className={styles.submitButton}>Enviar</button>
    </form>
  );
};

export default BudgetForm;
