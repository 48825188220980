import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/layouts/iconTextButton.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Ícone de seta para a direita

const IconTextButton = ({ to, text, className }) => {
  return (
    <Link className={`${styles.btn} ${styles[className]}`} to={to}>
      <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />
      <span className={styles.text}>{text}</span>
    </Link>
  );
};

export default IconTextButton;
