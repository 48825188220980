import React from 'react';
import styles from '../styles/pages/budget.module.css';
import avatarImage from '../assets/Membros/Atual/Gustavo_Euller.jpg';
import hoursImage from '../assets/relogio.png';

const BudgetDetails = () => {
  return (
    <div className={styles.budgetDetails}>
      <div className={styles.overlay}></div>
      <div className={styles.detailsLeft}>
        <div className={styles.personInfo}>
          <img src={avatarImage} alt="Gustavo Euller" />
          <div>
            <h3>Gustavo Euller</h3>
            <p>Diretor Comercial</p>
            <p>(31) 983072576</p>
            <p>linkedej@gmail.com</p>
          </div>
        </div>
        <div className={styles.hours}>
          <img src={hoursImage} alt="Horário" />
          <div>
            <h3>Nossos horários</h3>
            <p>Segunda à Sexta: 08:00 às 18:00</p>
            <p>Sábado: 08:00 às 16:00</p>
            <p>Domingo: Fechado</p>
          </div>
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.detailsRight}>
        <div className={styles.location}>
          <h3>Localização</h3>
          <div className={styles.mapContainer}>
            <iframe
              title='Localização LinkedEj' 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722.160812576942!2d-44.252188323983525!3d-21.10615397601082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa1c80a47ca4b45%3A0x83cc7c8bc22690c6!2sLinked%20Empresa%20Júnior!5e0!3m2!1spt-BR!2sbr!4v1726615507170!5m2!1spt-BR!2sbr" 
              width="600" 
              height="450" 
              style={{ border: 0 }}
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
          <p>UFSJ - Campus Tancredo Neves (CTAN), Sala 1.12</p>
          <p>Fábricas, São João del Rei - MG, 36301-360</p>
        </div>
      </div>
    </div>
  );
};

export default BudgetDetails;
